// import { Controller } from "stimulus"
import { Controller } from "@hotwired/stimulus"
export default class extends Controller {
  static targets = [ "field", "output1", "output2" ]
  static classes = [ "hide" ]
  static values = {
    showIfCountry: String,
    showIfLocal: String,
  }

  connect() {
    this.toggle()
  }

  toggle() {
    if (this.fieldTarget.value != this.showIfCountryValue) {
      this.output1Target.classList.add(this.hideClass)
    } else {
      this.output1Target.classList.remove(this.hideClass)
    }
    if (this.fieldTarget.value != this.showIfLocalValue) {
      this.output2Target.classList.add(this.hideClass)
    } else {
      this.output2Target.classList.remove(this.hideClass)
    }
  }
}