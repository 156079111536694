// import { Controller } from "stimulus"
import { Controller } from "@hotwired/stimulus"
export default class extends Controller {
  static targets = [
    "qualifyLeads",
    "qualifyLeadsDiv",
    "scheduleSalesCalls",
    "scheduleSalesCallsDiv",
    "submitLeads",
    "submitLeadsDiv",
  ];

  connect() {
    this.toggle();
  }

  toggle() {
    if (this.scheduleSalesCallsTarget.checked) {
      this.scheduleSalesCallsDivTarget.style.display = "block";
    } else {
      this.scheduleSalesCallsDivTarget.style.display = "none";
    }

    if (this.submitLeadsTarget.checked) {
      this.submitLeadsDivTarget.style.display = "block";
    } else {
      this.submitLeadsDivTarget.style.display = "none";
    }

    if (this.qualifyLeadsTarget.checked) {
      this.qualifyLeadsDivTarget.style.display = "block";
    } else {
      this.qualifyLeadsDivTarget.style.display = "none";
    }
  }

}
