// import { Controller } from "stimulus"
import { Controller } from "@hotwired/stimulus"
export default class extends Controller {
  static targets = [ "field", "customFormContent", "emailContent", "crmContent" ]
  static classes = [ "hide" ]
  static values = {
    showIfCrm: String,
    showIfEmail: String,
    showIfForm: String,
  }

  connect() {
    this.toggle()
  }

  toggle() {
    if (this.fieldTarget.value != this.showIfFormValue) {
      this.customFormContentTarget.classList.add(this.hideClass)
    } else {
      this.customFormContentTarget.classList.remove(this.hideClass)
    }

    if (this.fieldTarget.value != this.showIfEmailValue) {
      this.emailContentTarget.classList.add(this.hideClass)
    } else {
      this.emailContentTarget.classList.remove(this.hideClass)
    }

    if (this.fieldTarget.value != this.showIfCrmValue) {
      this.crmContentTarget.classList.add(this.hideClass)
    } else {
      this.crmContentTarget.classList.remove(this.hideClass)
    }
  }
}